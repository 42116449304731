import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SideMenu from './SideMenu/SideMenu';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@nextui-org/react';
import './GlobalLayout.css';
import { useMutation } from '@apollo/client';
import REFRESH_SIGN_IN from './mutations/refreshSignIn';
import { jwtDecode } from 'jwt-decode';
import { getToken, setLocalToken } from '../../utils/authUtils';
import { setUser } from '../../store/slices/authSlice';
import UserMenu from './UserMenu/UserMenu';
import SidePeek from '../SidePeek/SidePeek';
import LoadingSpinner from '../ui/LoadingSpinner/LoadingSpinner';
import Breadcrumb from './Breadcrumb/Breadcrumb';
import ScrollToTop from '../ui/ScrollToTop/ScrollToTop';
import { loadStripe } from '@stripe/stripe-js';
import PaymentStatusContainer from './PaymentStatusContainer/PaymentStatusContainer';
import PageLimit from './PageLimit/PageLimit';
import { useAnalytics } from '../../hooks/useAnalytics';
import { ErrorBoundary } from 'react-error-boundary';
import SEO from '../ui/SEO/SEO';
const BASE_URL = import.meta.env.BASE_URL;

// const stripePromise = loadStripe('pk_test_qwLL4s6ulmFQmibyucmnPXZm005s2P0qxo');

const GlobalLayout = ({
    children
}) => {

    const [landingHref, setLandingHref] = useState(() => window.location.href);
    const [landingOrigin, setLandingOrigin] = useState(() => window.location.origin);

    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useAnalytics();

    const user = useSelector(state => state.auth.user);

    const sidePeekIsOpen = useSelector(state => state.ui.sidePeek.open)

    const [refreshSignIn, { loading, error, data }] = useMutation(REFRESH_SIGN_IN, {
        onCompleted: (data) => {
            if (data && data.refreshSignIn.token) {
                setLocalToken(data.refreshSignIn.token);
                dispatch(setUser(data.refreshSignIn.user));
                if (location.pathname === '/') {
                    const landingPath = landingHref.substring(landingOrigin.length);
                    navigate(landingPath);
                }
            }
        }
    });
    const localToken = getToken();
    const decodedLocalToken = localToken ? jwtDecode(localToken) : null;

    useEffect(() => {
        if (decodedLocalToken) {
            const now = new Date();
            const expires = new Date(decodedLocalToken.exp * 1000);
            const hasExpired = expires < now;
            if (!hasExpired) {
                refreshSignIn({
                    variables: {
                        userID: decodedLocalToken.id
                    },
                })
            }
        }
    }, [])

    const [menuShape, setMenuShape] = useState('EXPANDED');
    const onCollapse = () => setMenuShape('COLLAPSED');
    const onExpand = () => setMenuShape('EXPANDED');
    const onToggleMenuShape = () => {
        if (menuShape === 'EXPANDED') {
            onCollapse()
        }
        if (menuShape === 'COLLAPSED') {
            onExpand()
        }
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
          // Trigger re-render of scatter plots on resize
          setWindowWidth(window.innerWidth)
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if ((windowWidth < 800 && menuShape === 'EXPANDED') || (windowWidth >= 640 && menuShape === 'MOBILE')) {
            setMenuShape('COLLAPSED');
        }
        if (windowWidth < 640) {
            setMenuShape('MOBILE');
        }
    }, [windowWidth]);

    // if ((localToken && loading)) {
    //     return (
    //         <div className='flex justify-center text-center h-[100vh]'>
    //             <div className='flex flex-col justify-center'>
    //                 <h3 className='text-4xl tracking-tighter font-bold -mb-6 text-mid-grey'>Lobby Report</h3>
    //                 <LoadingSpinner />
    //             </div>
    //         </div>
    //     )
    // }

    const bodyClasses = sidePeekIsOpen ? `flex-grow w-full lg:w-3/5` : `flex-grow`;


    if (location.pathname === '/' || location.pathname === '/about') {
        return (
            <div className='flex'>
                <SideMenu 
                    menuShape={menuShape}
                    onToggleMenuShape={onToggleMenuShape}
                />
                {/* <PageLimit user={user} /> */}
                <ErrorBoundary
                    fallback={'test'}
                >
                    <div className={bodyClasses}>
                        <PaymentStatusContainer />
                        <div className='bg-white py-2 pl-6 pr-4 flex w-full justify-between z-30 sticky top-0'>
                            <Breadcrumb location={location} />
                            <UserMenu user={user} />
                        </div>
                        <div className='mx-4 sm:w-5/6 sm:mx-auto max-w-6xl'>
                            <Outlet />
                        </div>
                    </div>
                    {sidePeekIsOpen && <div className='flex-grow w-0 lg:w-2/5'></div>}
                    <SidePeek 
                        isOpen={sidePeekIsOpen}
                        menuShape={menuShape}
                    />
                    <ScrollToTop />
                </ErrorBoundary>
            </div>
        )
    }

    return (
        <div className='flex'>
            <SEO
                location={location}
                searchParams={searchParams}
            />
            <SideMenu 
                menuShape={menuShape}
                onToggleMenuShape={onToggleMenuShape}
            />
            {/* <PageLimit user={user} /> */}
            <div className={bodyClasses}>
                <PaymentStatusContainer />
                <div className='bg-white py-2 pr-3 flex w-full justify-between z-30 sticky top-0'>
                    <div className='mx-4 hidden sm:w-5/6 sm:mx-auto max-w-6xl sm:flex justify-between'>
                        <Breadcrumb location={location} />
                    </div>
                    <div className='fixed right-3 hidden sm:block'>
                        <UserMenu user={user} />
                    </div>
                </div>
                <div className='py-16 sm:py-12 sm:mx-4 sm:w-5/6 sm:mx-auto max-w-6xl'>
                    <Outlet />
                </div>
            </div>
            {sidePeekIsOpen && <div className='flex-grow w-0 lg:w-2/5'></div>}
            <SidePeek 
                isOpen={sidePeekIsOpen}
                menuShape={menuShape}
            />
            <ScrollToTop />
        </div>
    )
}

export default GlobalLayout;