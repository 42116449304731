import { faAnglesDown, faBars, faBriefcase, faBurger, faCalendar, faChevronLeft, faChevronRight, faHandshakeSimple, faIndustry, faInstitution, faMinimize, faMinus, faPerson, faQuestionCircle, faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useSearchParams } from 'react-router-dom';
import './SideMenu.css';
import { faCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons';
import { Button, Modal, ModalContent, Tooltip } from '@nextui-org/react';
import Search from './Search/Search';
import { useQuery } from '@apollo/client';
import FOLLOWS from './queries/follows';
import FollowListItem from './FollowListItem/FollowListItem';
import LoadingFollowList from './LoadingFollowList/LoadingFollowList';
import { MID_GREY } from '../../../utils/colours';
import MobileMenu from './MobileMenu/MobileMenu';
import MobileUserMenu from './MobileUserMenu/MobileUserMenu';
import Logo from '../../ui/Logo/Logo';
import MobileTopMenu from './MobileTopMenu/MobileTopMenu';

const SideMenu = ({
    menuShape,
    onToggleMenuShape
}) => {

    const { loading, error, data } = useQuery(FOLLOWS);
    const [searchParams, setSearchParams] = useSearchParams();
    const [mobileMenuIsOpen, toggleMobileMenu] = useState(false);
    const openMobileMenu = () => toggleMobileMenu(true);
    const closeMobileMenu = () => toggleMobileMenu(false);

    const [mobileUserMenuIsOpen, toggleUserMobileMenu] = useState(false);
    const openMobileUserMenu = () => toggleUserMobileMenu(true);
    const closeMobileUserMenu = () => toggleUserMobileMenu(false);

    const searchName = searchParams.get('name')

    let followList = <LoadingFollowList />;
    if (data) {
        followList = data.follows.map((follow, i) => {
            return (
                <FollowListItem key={i} searchName={searchName} {...follow} />
            )
        })
    }

    let containerClasses = 'bg-gray-100 w-64 min-w-64 h-screen';
    let menuClasses = 'fixed SideMenu w-64 bg-offwhite h-screen pb-32';
    let indexClasses = 'hidden-scrollbars flex flex-col font-medium w-full pt-1 px-4 pb-24 overflow-scroll';
    let navLinkClasses = 'flex gap-2 items-center w-full px-2 py-0.5 leading-snug rounded-lg';
    let headerClasses = 'flex pt-4 px-4 mb-1.5 justify-between';
    if (menuShape === 'COLLAPSED') {
        containerClasses = 'bg-gray-100 w-10 min-w-10 h-screen';
        menuClasses = 'fixed SideMenu w-10 bg-offwhite h-screen pb-32 px-1';
        indexClasses = 'hidden-scrollbars flex flex-col font-medium w-full pt-1 px-0 pb-24 overflow-scroll'
        navLinkClasses = 'flex gap-2 items-center justify-center w-full px-0 py-0.5 rounded-lg';
        headerClasses = 'flex pt-4 px-2 mb-3 justify-between'
    }
    if (menuShape === 'MOBILE') {
        containerClasses = 'w-full fixed z-50';
        menuClasses = 'fixed SideMenu w-full top-0';
        indexClasses = 'hidden'
        navLinkClasses = 'flex gap-2 items-center w-full px-0 pt-2 pb-2 rounded-lg';
        headerClasses = 'flex pt-0 px-2 mb-3 justify-between'
    }

    return (
        <div className={containerClasses}>
            <div className={menuClasses}>
                {menuShape === 'MOBILE' && (
                    <div className='text-center flex flex-col gap-0.5 pt-1'>
                        
                        <MobileTopMenu 
                            openMobileMenu={openMobileMenu}
                        />

                        <MobileMenu
                            isOpen={mobileMenuIsOpen}
                            onClose={closeMobileMenu}
                        />
                        <MobileUserMenu
                            isOpen={mobileUserMenuIsOpen}
                            onClose={closeMobileUserMenu}
                        />
                    </div>
                )}
                <div className={headerClasses}>
                    {menuShape === 'EXPANDED' && (
                        <NavLink to='/' className={'wordmark'}>
                            <div className='flex items-center ml-1 gap-2'>
                                <p className='font-semibold tracking-tight text-lg cursor-pointer hover:bg-transparent'>
                                    Lobby Report
                                </p>
                            </div>
                        </NavLink>
                    )}
                    {menuShape !== 'MOBILE' && (
                        <Tooltip 
                            content={menuShape === 'EXPANDED' ? "Hide menu" : "Show menu"}
                            className='bg-black text-white'
                            placement='right'
                        >
                            <button onClick={onToggleMenuShape} 
                                className='px-0 py-0 bg-transparent collapse-toggle'
                            >
                                <FontAwesomeIcon 
                                    icon={menuShape !== 'EXPANDED' ? faBars : faChevronLeft} 
                                    className='fas text-mid-grey opacity-80 hover:opacity-100 transition-opacity' 
                                    size='sm'
                                    style={{
                                        transform: menuShape !== 'EXPANDED' ? 'rotate(0deg)' : 'rotate(0deg)'
                                    }}
                                />
                            </button>
                        </Tooltip>
                    )}
                </div>
                {menuShape !== 'MOBILE' && (
                    <div className={menuShape === 'COLLAPSED' ? 'flex flex-col px-1' : 'flex flex-col px-4'}>
                        <Search 
                            collapsed={menuShape === 'COLLAPSED'}
                            menuShape={menuShape}
                        />
                    </div>
                )}
                <div className={indexClasses}
                    style={{ height: menuShape !== 'MOBILE' ? `calc(100vh - 96px)` : 'auto' }}
                >
                    <NavLink to='/timeline'
                        className={navLinkClasses}
                        id='TimelineNavLink'
                    >
                        <Tooltip 
                            content='Recent Lobbying'
                            color='foreground' 
                            placement='right'
                            isDisabled={menuShape === 'EXPANDED'}
                        >
                            <div className={menuShape === 'EXPANDED' ? 'min-w-[20px] text-left -ml-px mr-px' : ''}>
                                <FontAwesomeIcon 
                                    icon={faCalendar} 
                                    className='fas text-mid-grey' 
                                    size='xs'
                                />
                            </div>
                        </Tooltip>
                        {menuShape === 'EXPANDED' && (
                            <span className='text-sm font-medium'>
                                Recent Lobbying
                            </span>
                        )}
                    </NavLink>
                    {/* <div className=''> */}
                        <NavLink to='/companies'
                            className={navLinkClasses}
                        >
                            <Tooltip 
                                content='Companies'
                                color='foreground' 
                                placement='right'
                                isDisabled={menuShape === 'EXPANDED'}
                            >
                                <div className={menuShape === 'EXPANDED' ? 'min-w-[20px]' : ''}>
                                    <FontAwesomeIcon 
                                        icon={faIndustry} 
                                        className='fas text-mid-grey' 
                                        size='xs'
                                    />
                                </div>
                            </Tooltip>
                            {menuShape === 'EXPANDED' && (
                                <span className='text-sm font-medium'>
                                    Companies
                                </span> 
                            )}
                        </NavLink>
                        <NavLink to='/consulting-firms'
                            className={navLinkClasses}
                        >
                            <Tooltip 
                                content='Consulting Firms'
                                color='foreground' 
                                placement='right'
                                isDisabled={menuShape === 'EXPANDED'}
                            >
                                <div className={menuShape === 'EXPANDED' ? 'min-w-[20px] ml-px' : '-mr-px'}>
                                    <FontAwesomeIcon 
                                        icon={faBriefcase} 
                                        className='fas text-mid-grey'
                                        size='xs' 
                                    />
                                </div>
                            </Tooltip>
                            {menuShape === 'EXPANDED' && (
                                 <span className='text-sm font-medium'>
                                    Consulting Firms
                                </span>
                            )}
                        </NavLink>
                        <NavLink to='/lobbyists'
                            className={navLinkClasses}
                        >
                            <Tooltip 
                                content='Lobbyists'
                                color='foreground' 
                                placement='right'
                                isDisabled={menuShape === 'EXPANDED'}
                            >
                                <div className={menuShape === 'EXPANDED' ? 'min-w-[20px] ml-[2px]' : ''}>
                                    <FontAwesomeIcon 
                                        icon={faUser} 
                                        className='fas text-mid-grey' 
                                        size='xs'
                                    />
                                </div>
                            </Tooltip>
                            {menuShape === 'EXPANDED' && (
                                 <span className='text-sm font-medium'>
                                    Lobbyists
                                </span>
                            )}
                        </NavLink>
                    {/* </div> */}
                    <NavLink to='/public-servants'
                        className={navLinkClasses}
                    >
                        <Tooltip 
                            content='Government Officials'
                            color='foreground' 
                            placement='right'
                            isDisabled={menuShape === 'EXPANDED'}
                        >  
                            <div className={menuShape === 'EXPANDED' ? 'min-w-[20px] ml-px' : ''}>
                                <FontAwesomeIcon 
                                    icon={faCanadianMapleLeaf} 
                                    className='fas text-mid-grey' 
                                    size='xs'
                                />
                            </div>
                        </Tooltip>
                        {menuShape === 'EXPANDED' && (
                             <span className='text-sm font-medium'>
                                Government Officials
                            </span>
                        )}
                    </NavLink>
                    <NavLink to='/institutions'
                        className={navLinkClasses}
                    >
                        <Tooltip 
                            content='Parts of Government'
                            color='foreground' 
                            placement='right'
                            isDisabled={menuShape === 'EXPANDED'}
                        >      
                            <div className={menuShape === 'EXPANDED' ? 'min-w-[20px] ml-px' : ''}>
                                <FontAwesomeIcon 
                                    icon={faInstitution} 
                                    className='fas text-mid-grey' 
                                    size='xs'
                                />
                            </div>
                        </Tooltip>
                        {menuShape === 'EXPANDED' && (
                             <span className='text-sm font-medium'>
                                Parts of Government
                            </span>
                        )}
                    </NavLink>
                    {followList.length > 0 && menuShape === 'EXPANDED' && (
                        <div>
                            <p className='font-bold text-xs mb-0 px-2 mt-5 border-t border-gray-300 pt-7 mb-2'>
                                Who I follow
                            </p>
                            <div className='flex flex-col gap-0.5 leading-snug'>
                                {followList}
                            </div>
                        </div>
                    )}
                    <div className={menuShape === 'EXPANDED' ? 'mt-4 border-t border-gray-200 pt-4' : 'mt-4'}>
                        <NavLink to='/about'
                            className={navLinkClasses}
                        >
                            <Tooltip 
                                content='About this site'
                                color='foreground' 
                                placement='right'
                                isDisabled={menuShape === 'EXPANDED'}
                            >      
                                <div className={menuShape === 'EXPANDED' ? 'min-w-[20px] ml-px' : ''}>
                                    <FontAwesomeIcon 
                                        icon={faQuestionCircle} 
                                        className='fas text-mid-grey' 
                                        size='sm'
                                    />
                                </div>
                            </Tooltip>
                            {menuShape === 'EXPANDED' && (
                                <span className='text-sm font-medium'>
                                    About this site
                                </span>
                            )}
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SideMenu;