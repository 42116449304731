import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, CircularProgress } from '@nextui-org/react';
import PublicServantBlock from '../../../FirmProfile/FirmConnections/PublicServantBlock/PublicServantBlock';
import InfiniteScroll from 'react-infinite-scroller';
import PUBLIC_SERVANT_COMPANIES from './queries/publicServantCompanies';
import CompanyBlock from '../../../PublicServantProfile/PublicServantConnections/CompanyBlock/CompanyBlock';
import LoadingSidePeek from '../../../ui/LoadingSidePeek/LoadingSidePeek';
import LoadingSpinner from '../../../ui/LoadingSpinner/LoadingSpinner';
import SidePeekHeader from '../../../ui/SidePeekHeader/SidePeekHeader';
import SidePeekBody from '../../../ui/SidePeekBody/SidePeekBody';
import { MAIN_BUTTON_CLASSES } from '../../../../utils/formatters';

const PublicServantCompanies = ({
    onClose,
    givenName
}) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const publicServant = searchParams.get('name');
    const [loadingMore, setLoadingMore] = useState(false);

    const { loading, error, data, fetchMore } = useQuery(PUBLIC_SERVANT_COMPANIES, {
        variables: {
            name: publicServant,
            offset: 0
        },
        fetchPolicy: 'network-only'
    });

    const onFetchMore = () => {
        setLoadingMore(true);
        fetchMore({
            variables: {
                offset: data.relationships.length
            }
        })
    }

    useEffect(() => {
        if (loadingMore) {
            setLoadingMore(false);
        }
    }, [data])

    let body;

    if (loading) {
        return (
            <div>
                <SidePeekHeader
                    heading={publicServant}
                    subheading={`Recent meetings with...`}
                    onClose={onClose}
                />
                <LoadingSidePeek />
            </div>
        )
    }

    const contactCards = data.relationships.map((rel, i) => {
        return (
            <CompanyBlock
                key={i}
                {...rel}
            />
        )
    });

    body = (
        <SidePeekBody>
            {contactCards}
            {data.totalRelationships > data.relationships.length && (
                <Button
                    onClick={onFetchMore}
                    className={MAIN_BUTTON_CLASSES}
                    isLoading={loadingMore}
                    disableAnimation
                    disableRipple
                >
                    {loadingMore ? 'Loading...' : 'Show more'}
                </Button>
            )}
        </SidePeekBody>
    )

    return (
        <div>
            <SidePeekHeader
                heading={publicServant}
                subheading={`Recent meetings with ${data.totalRelationships} companies`}
                onClose={onClose}
            />
            {body}
        </div>
    )
}

export default PublicServantCompanies;