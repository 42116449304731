import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, CircularProgress } from '@nextui-org/react';
import InfiniteScroll from 'react-infinite-scroller';
import INSTITUTION_STAFF from './queries/institutionStaff';
import PublicServantBlock from '../../../FirmProfile/FirmConnections/PublicServantBlock/PublicServantBlock';
import LoadingSidePeek from '../../../ui/LoadingSidePeek/LoadingSidePeek';
import LoadingSpinner from '../../../ui/LoadingSpinner/LoadingSpinner';
import { MAIN_BUTTON_CLASSES } from '../../../../utils/formatters';
import SidePeekHeader from '../../../ui/SidePeekHeader/SidePeekHeader';
import SidePeekBody from '../../../ui/SidePeekBody/SidePeekBody';

const InstitutionStaff = ({
    onClose
}) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const institution = searchParams.get('name');
    const [loadingMore, setLoadingMore] = useState(false);

    const { loading, error, data, fetchMore } = useQuery(INSTITUTION_STAFF, {
        variables: {
            institution: institution,
            offset: 0
        },
        fetchPolicy: 'cache-and-network'
    });

    const onFetchMore = () => {
        setLoadingMore(true);
        fetchMore({
            variables: {
                offset: data.officials.length
            }
        })
    }

    useEffect(() => {
        if (loadingMore) {
            setLoadingMore(false);
        }
    }, [data])

    let body;

    if (loading) {
        return (
            <div>
                <SidePeekHeader
                    heading={institution}
                    subheading={`Public office holders`}
                    onClose={onClose}
                />
                <LoadingSidePeek />
            </div>
        )
    }

    const staffList = data.officials.map((rel, i) => {
        return (
            <PublicServantBlock
                key={i}
                officialName={rel.name}
                meetings={rel.meetingCount}
                {...rel}
            />
        )
    });

    body = (
        <SidePeekBody>
            {staffList}
            {data.totalOfficials > data.officials.length && (
                <Button 
                    onClick={onFetchMore}
                    className={MAIN_BUTTON_CLASSES}
                    isLoading={loadingMore}
                    disableAnimation
                    disableRipple
                >
                    {loadingMore ? 'Loading...' : 'Show more'}
                </Button>
            )}
        </SidePeekBody>
    )

    return (
        <div>
            <SidePeekHeader
                heading={institution}
                subheading={`Public office holders`}
                onClose={onClose}
            />
            {body}
        </div>
    )
}

export default InstitutionStaff;