import React from "react";
import { Card } from "@nextui-org/react";
import { Link } from "react-router-dom";

const CompanyBlock = ({
    meetings,
    clientName,
    contextName
}) => {

    return (
        <Link to={`/company/?name=${encodeURIComponent(clientName)}`}
            className="block leading-snug rounded-lg"
        >
            <Card shadow='sm' className="px-5 py-4">
                <p 
                    className="truncate sm:text-md font-bold" 
                    // onClick={openOfficialSidePeek}
                >
                    {clientName}
                </p>
                <p className="text-dark-grey sm:text-xs sm:mt-2">
                    {meetings} meeting{meetings > 1 ? 's' : ''} {contextName ? `with ${contextName}` : ''} this year
                </p>
            </Card>
        </Link>
    )

}

export default CompanyBlock;