import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, CircularProgress } from '@nextui-org/react';
import InfiniteScroll from 'react-infinite-scroller';
import INSTITUTION_COMPANIES from './queries/institutionCompanies';
import CompanyBlock from '../../../PublicServantProfile/PublicServantConnections/CompanyBlock/CompanyBlock';
import LoadingSidePeek from '../../../ui/LoadingSidePeek/LoadingSidePeek';
import LoadingSpinner from '../../../ui/LoadingSpinner/LoadingSpinner';
import { MAIN_BUTTON_CLASSES } from '../../../../utils/formatters';
import SidePeekHeader from '../../../ui/SidePeekHeader/SidePeekHeader';
import SidePeekBody from '../../../ui/SidePeekBody/SidePeekBody';

const InstitutionCompanies = ({
    onClose
}) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const institution = searchParams.get('name');

    const [loadingMore, setLoadingMore] = useState(false);

    const { loading, error, data, fetchMore } = useQuery(INSTITUTION_COMPANIES, {
        variables: {
            institution: institution,
            offset: 0
        },
        fetchPolicy: 'cache-and-network'
    });

    const onFetchMore = () => {
        setLoadingMore(true);
        fetchMore({
            variables: {
                offset: data.relationships.length
            }
        })
    }

    useEffect(() => {
        if (loadingMore) {
            setLoadingMore(false);
        }
    }, [data])

    let body;

    if (loading) {
        return (
            <div>
                <SidePeekHeader
                    heading={institution}
                    subheading={`Recent meetings with...`}
                    onClose={onClose}
                />
                <LoadingSidePeek />
            </div>
        )
    }

    const contactCards = data.relationships.map((rel, i) => {
        return (
            <CompanyBlock
                key={i}
                {...rel}
            />
        )
    });

    body = (
        <SidePeekBody>
            {contactCards}
            {data.totalRelationships > data.relationships.length && (
                <Button
                    className={MAIN_BUTTON_CLASSES}
                    onClick={onFetchMore}
                    disableAnimation
                    disableRipple
                    isLoading={loadingMore}
                >
                    {loadingMore ? 'Loading...' : 'Show more'}
                </Button>
            )}
        </SidePeekBody>
    )

    return (
        <div>
            <SidePeekHeader
                heading={institution}
                subheading={`Recent meetings with ${data.totalRelationships} companies`}
                onClose={onClose}
            />
            {body}
        </div>
    )
}

export default InstitutionCompanies;