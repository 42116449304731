import React from 'react';

const SidePeekHeader = ({
    heading,
    subheading,
    onClose
}) => {
    return (
        <div className='pl-4 pr-1.5 pt-1 pb-1 sticky top-0 z-10 bg-white flex border-b border-gray-200'>
            <div className='flex-grow pt-2.5'>
                <h3 className='text-xl font-bold mb-0 leading-tight truncate w-90'>{heading}</h3>
                <h3 className='font-medium mt-0 mb-3 leading-tight text-dark-grey truncate'>{subheading}</h3>
            </div>
            <div>
                <button 
                    className='px-2 py-0 bg-white hover:bg-gray-100 absolute top-1 right-1' 
                    onClick={onClose}
                >
                    <span className='text-2xl font-normal text-dark-grey'>×</span>
                </button>
            </div>
        </div>
    )
}

export default SidePeekHeader;