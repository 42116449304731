import { Chip, Tooltip } from "@nextui-org/react";
import React from "react";
import InstitutionName from "../InstitutionName/InstitutionName";

const getAcronym = (institution) => {
    if (!institution.indexOf('(') || institution.indexOf('(') === -1) {
        return institution;
    }
    const acronym = institution.substring(institution.indexOf('(')+1,institution.indexOf(')'));
    return acronym;
}

export const getInstitutionAcronym = (institution) => {
    let acronym;
    switch (institution) {
        case 'Infrastructure Canada (INFC)': acronym = 'INFC'; break;
        case 'Innovation, Science and Economic Development Canada (ISED)': acronym = 'ISED'; break;
        case 'Canada Revenue Agency (CRA)': acronym = 'CRA'; break;
        case `Prime Minister's Office (PMO)`: acronym = `PMO`; break;
        case `Transport Canada (TC)`: acronym = `TC`; break;
        case `Intergovernmental Affairs Secretariat (IGA)`: acronym = `IGA`; break;
        case `Health Canada (HC)`: acronym = 'HC'; break;
        case `Finance Canada (FIN)`: acronym = 'FIN'; break;
        case 'Indigenous Services Canada (ISC)': acronym = 'ISC'; break;
        case `Accessibility Standards Canada`: acronym = 'ASC'; break;
        case `Fisheries and Oceans Canada (DFO)`: acronym = 'DFO'; break;
        case `Agriculture and Agri-Food Canada (AAFC)`: acronym = 'AAFC'; break;
        case 'Natural Resources Canada (NRCan)': acronym = 'NRCan'; break;
        case `House of Commons`: acronym = 'Commons'; break;
        case `Senate of Canada`: acronym = `Senate`; break;
        case `Employment and Social Development Canada (ESDC)`: acronym = 'ESDC'; break;
        case `Global Affairs Canada (GAC)`: acronym = 'GAC'; break;
        case `Women and Gender Equality (WAGE)`: acronym = 'WAGE'; break;
        case `Canadian Heritage (PCH)`: acronym = 'PCH'; break;
        case `National Defence (DND)`: acronym = `DND`; break;
        case `Environment and Climate Change Canada (ECCC)`: acronym = `ECCC`; break;
        case `Export Development Canada (EDC)`: acronym = `EDC`; break;
        case `Canada Economic Development for Quebec Regions`: acronym = 'CED'; break;
        case `Canadian Radio-television and Telecommunications Commission (CRTC)`: acronym = 'CRTC'; break;
        case 'Treasury Board Of Canada Secretariat (TBS)': acronym = 'TBS'; break;
        case 'Public Safety Canada (PS)': acronym = 'PS'; break;
        case 'Justice Canada (JC)': acronym = 'JC'; break;
        case 'Impact Assessment Agency of Canada (IAAC)': acronym = 'IAAC'; break;
        case 'National Research Council (NRC)': acronym = 'NRC'; break;
        case 'Immigration, Refugees and Citizenship Canada (IRCC)': acronym = `IRCC`; break;
        case 'Crown-Indigenous Relations and Northern Affairs Canada (CIRNAC)': acronym = 'CIRNAC'; break;
        case `Canada Border Services Agency (CBSA)`: acronym = 'CBSA'; break;
        case `Public Services and Procurement Canada (PSPC)`: acronym = 'PSPC'; break;
        case `Canadian Northern Economic Development Agency (CanNor)`: acronym = `CanNor`; break;
        case `Federal Economic Development Agency for Southern Ontario (FedDev)`: acronym = `FedDev`; break;
        case `Privy Council Office (PCO)`: acronym = `PCO`; break;
        case `Canada School of Public Service (CSPS)`: acronym = `CSPS`; break;
        case `Royal Canadian Mounted Police (RCMP)`: acronym = `RCMP`; break;
        case `Atlantic Canada Opportunities Agency (ACOA)`: acronym = `ACOA`; break;
        case `Prairies Economic Development Canada (PrairiesCan)`: acronym = `PrairiesCan`; break;
        case `Veterans Affairs Canada (VAC)`: acronym = `VAC`; break;
        case `Pacific Economic Development Canada (PacifiCan)`: acronym = `PacifiCan`; break;
        case `Canadian Security Intelligence Service (CSIS)`: acronym = `CSIS`; break;
        case `Canadian Space Agency (CSA)`: acronym = `CSA`; break;
        case `Financial Consumer Agency of Canada (FCAC)`: acronym = `FCAC`; break;
        case `Shared Services Canada (SSC)`: acronym = `SSC`; break;
        case `Communications Security Establishment Canada (CSEC)`: acronym = `CSEC`; break;
        case `Service Canada (ServCan)`: acronym = `ServCan`; break;
        case `Transportation Safety Board of Canada (TSB)`: acronym = `TSB`; break;
        case `Federal Economic Development Agency for Northern Ontario`: acronym = `FedDev`; break;
        case `Canada Mortgage and Housing Corporation (CMHC)`: acronym = `CMHC`; break;
        case `Rural Economic Development (Minister’s Office)`: acronym = `RED`; break;
        // default: acronym = institution;
        default: acronym = getAcronym(institution);
    }
    return acronym;
}

const InstitutionChip = ({
    institution
}) => {

    const acronym = getInstitutionAcronym(institution);

    return (
        <Tooltip 
            color='foreground' 
            content={<InstitutionName fullName={institution} />}
        >
            <span className='text-gray-800 font-medium bg-mid-grey text-white rounded-xl text-xs tracking-tight px-2 pt-1 py-1 leading-none overflow-ellipsis whitespace-nowrap inline-block truncate ellipsis max-w-24'>
                {acronym}
            </span>
        </Tooltip> 
    )

}

export default InstitutionChip;