import React from 'react';
import { Link } from 'react-router-dom';

const Footer = ({
    align = 'text-left',
    home
}) => {

    return (
        <div className="mt-24 border-t border-gray-200 pt-4">
            <p className={`text-sm leading-snug sm:text-xs font-medium text-left md:${align}`}>
                <span className='opacity-70'>
                    The information provided on this website is provided “as is”, subject to
                </span> <Link to='/disclaimer'
                    className='text-accent-fade hover:text-accent underline'
                >
                    our disclaimer
                </Link>
                <span className='opacity-70'>
                    , and may not be accurate and/or complete. 
                </span>
            </p>
        </div>
    )
}

export default Footer;