import React from 'react';

const SidePeekBody = ({
    children
}) => {
    return (
        <div 
            className='overflow-scroll hidden-scrollbars'
            style={{
                height: `calc(100vh - 80px)`
            }}
        >
            <div className='px-4 pt-4 pb-16 flex flex-col gap-3'>
                {children}
            </div>
        </div>
    )
}

export default SidePeekBody;