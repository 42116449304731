import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, CircularProgress } from '@nextui-org/react';
import InfiniteScroll from 'react-infinite-scroller';
import FirmCard from '../../../FirmCard/FirmCard';
import LOBBYIST_CLIENTS from './queries/lobbyistClients';
import LoadingSidePeek from '../../../ui/LoadingSidePeek/LoadingSidePeek';
import LoadingSpinner from '../../../ui/LoadingSpinner/LoadingSpinner';
import SidePeekHeader from '../../../ui/SidePeekHeader/SidePeekHeader';
import SidePeekBody from '../../../ui/SidePeekBody/SidePeekBody';

const LobbyistClients = ({
    onClose
}) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const lobbyist = searchParams.get('name');
    const [loadingMore, setLoadingMore] = useState(false);

    const { loading, error, data, fetchMore } = useQuery(LOBBYIST_CLIENTS, {
        variables: {
            lobbyist: lobbyist
        },
        fetchPolicy: 'cache-and-network'
    });

    const onFetchMore = () => {
        fetchMore({
            variables: {
                offset: data.firms.length
            }
        })
    }

    useEffect(() => {
        if (loadingMore) {
            setLoadingMore(false);
        }
    }, [data])

    let body;

    if (loading) {
        return (
            <div>
                <SidePeekHeader
                    heading={lobbyist}
                    subheading={'Active clients'}
                    onClose={onClose}
                />
                <div className='pl-8 pr-1.5 pt-1 pb-4 sticky top-0 z-10 bg-white h-20 flex border-b border-gray-200'>
                    <div className='flex-grow pt-2.5'>
                        <h3 className='text-xl font-bold mb-0 leading-tight truncate w-4/5'>{lobbyist}</h3>
                        <h3 className='text-xl font-medium mt-0 leading-tight text-gray-500 truncate w-4/5'>Active clients</h3>
                    </div>
                    <div>
                        <button 
                            className='px-2 py-0 bg-white hover:bg-gray-100' 
                            onClick={onClose}
                        >
                            <span className='text-2xl font-normal text-gray-500'>×</span>
                        </button>
                    </div>
                </div>
                <LoadingSidePeek />
            </div>
        )
    }

    const clientList = data.firms.map((firm, i) => {
        return (
            <FirmCard 
                key={i} 
                fixedHeight={false}
                {...firm} 
            />
        )
    })

    body = (
        <SidePeekBody>
            {clientList}
            {data.firms.length > data.firms.length && (
                <Button
                    size='sm'
                    onClick={onFetchMore}
                    className='mt-4 mx-8 font-semibold'
                    isLoading={loadingMore}
                    disableAnimation
                    disableRipple
                >
                    {loadingMore ? 'Loading...' : 'Show more'}
                </Button>
            )}
        </SidePeekBody>
    )

    return (
        <div>
            <SidePeekHeader
                heading={lobbyist}
                subheading={`${data.totalClients} active clients`}
                onClose={onClose}
            />
            {body}
        </div>
    )
}

export default LobbyistClients;